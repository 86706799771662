'use client';

import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useSearchParams } from 'next/navigation';
import logo from '@shared/auth/images/logo.CaSBxDvB.png';
import { AppRoutes } from '@shared/constants/appRoutes';

const Component: React.FC = () => {
  const params = useSearchParams();
  const paramsString = params.toString();

  let href = AppRoutes.index as string;
  if (paramsString) {
    href = `${href}?${paramsString}`;
  }

  return (
    <Link href={href}>
      <Image alt="Logo" className="w-[180px] h-[40px]" src={logo} />
    </Link>
  );
};

export const HeaderLogo: React.FC = () => {
  return (
    <React.Suspense>
      <Component />
    </React.Suspense>
  );
};
