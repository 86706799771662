'use client';

import React from 'react';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';

const Component = () => {
  const params = useSearchParams();
  const paramsString = params.toString();

  let href = 'https://viralmango.com/privacy-policy';
  if (paramsString) {
    href = `${href}?${paramsString}`;
  }

  return (
    <Link
      href={href}
      title="Learn more about our Privacy Policy"
      className="footer-link hover:text-indigo-600"
      aria-label="Learn more about our Privacy Policy"
    >
      Privacy Policy
    </Link>
  );
};

export const FooterPrivacyPolicy = () => {
  return (
    <React.Suspense>
      <Component />
    </React.Suspense>
  );
};
