'use client';

import dynamic from 'next/dynamic';

export const PostHogPageView = dynamic(
  () => import('@shared/providers/PostHogPageView'),
  {
    ssr: false,
  },
);
